<template>
  <div class="home">
    <div ref="videoContainer" class="header">
      <video
          src="@/assets/batch.mp4"
          autoplay
          muted
          playsinline
          loop
          width="100%"
          height="100%"
          id="video"
          ref="video"
          poster="@/assets/init-vedio.png"
      ></video>
<!--       @loadedmetadata="setVideoRatio"-->
    </div>
    <div class="bottom">
      <div class="crm ele-card">
        <div class="WebMain">
          <div class="title">
            <b class="p30">{{$t('home.title')}}</b>
            <p></p>
          </div>
          <div class="main">
          <img class="wow fadeInLeft" src="@/assets/img/index/webAndMobile.png" alt="" />
           <div class="p16">
             <p class="desc">
               {{$t('home.desc1')}}
             </p>
<!--             <p class="desc">-->
<!--               {{$t('home.desc2')}}-->
<!--             </p>-->
           </div>
          </div>

        </div>
      </div>
      <div class="crm highlight">
        <div class="WebMain">
          <div class="title">
            <p class="p30">{{$t('home.title1')}}</p>
            <p></p>
          </div>
          <div class="radiate">
            <div class="short flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}1.svg`)" class="icon"/>
                <p class="subtitle">{{$t('home.subTitle1')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}2.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle2')}}</p>
              </div>
            </div>
            <div class="long flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}3.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle3')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}4.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle4')}}</p>
              </div>
            </div>
            <div class="short flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}5.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle5')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}6.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle6')}}</p>
              </div>
            </div>
            <img class="center-img" src="@/assets/img/home/more-plat.png">
          </div>
        </div>
        <div class="design">
          <div class="design-box ">
            <img class="more-phone" src="@/assets/img/home/more_phone.png"/>
            <div :class="`triangle ${ $store.state.style === 'black' ? 'dark-tri':'light-tri'}`"></div>
            <div class="right-label">
              <div class="title">
                <p class="p24">{{$t('home.subTitle6')}}</p>
                <p></p>
              </div>
              <div class="subtitles">
                <span>{{$t('home.subTitle7')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="WebMain">
          <div class="title">
            <p class="p30">{{$t('home.title3')}}</p>
            <p></p>
          </div>
          <div class="center">
            <div class="item" v-for="item in imgList" :key="item.id">
              <img
                :src="require(`../../assets/img/index/${item.path}`)"
                alt=""
              />
            </div>
          </div>
<!--          <div class="bottom">-->
<!--            <div class="item" v-for="item in imgList2" :key="item.id">-->
<!--              <img-->
<!--                :src="require(`../../assets/img/index/${item.path}`)"-->
<!--                alt=""-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="xs">
      <div class="crm ele-card">
        <div class="WebMain">
          <div class="title">
            <b >{{$t('home.title')}}</b>
            <p></p>
          </div>
          <div class="main">
            <div class="p16">
              <p class="desc">
                {{$t('home.desc1')}}
              </p>
            </div>
            <img class="wow fadeInLeft" src="@/assets/img/index/webAndMobile.png" alt="" />
          </div>

        </div>
      </div>
      <div class="crm highlight">
        <div class="WebMain">
          <div class="title">
            <p >{{$t('home.title1')}}</p>
            <p></p>
          </div>
          <div class="radiate">
            <div class="short flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}1.svg`)" class="icon"/>
                <p class="subtitle">{{$t('home.subTitle1')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}2.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle2')}}</p>
              </div>
            </div>
            <div class="long flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}3.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle3')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}4.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle4')}}</p>
              </div>
            </div>
            <div class="short flex">
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}5.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle5')}}</p>
              </div>
              <div class="func">
                <img :src="require(`@/assets/img/home/${$store.state.style == 'black' ?'dark':'light'}6.svg`)"  class="icon"/>
                <p class="subtitle">{{$t('home.subTitle6')}}</p>
              </div>
            </div>
            <img class="center-img" src="@/assets/img/home/more-plat.png">
          </div>
        </div>
        <div class="design">
          <div class="right-label">
            <div class="title">
              <p >{{$t('home.subTitle6')}}</p>
              <p></p>
            </div>
            <div class="subtitles">
              <span>{{$t('home.subTitle7')}}</span>
            </div>
            <img class="more-phone" src="@/assets/img/home/more_phone.png"/>
          </div>
        </div>
      </div>
      <div class="partner highlight">
        <div class="WebMain">
          <div class="title">
            <p >{{$t('home.title3')}}</p>
            <p></p>
          </div>
          <div class="center">
            <div class="item" >
              <img  src="@/assets/img/index/icon (1).png" alt=""/>
              <img  src="@/assets/img/index/icon (2).png" alt=""/>
              <img  src="@/assets/img/index/icon (3).png" alt=""/>
            </div>
            <div class="item" >
              <img  src="@/assets/img/index/icon (4).png" alt=""/>
              <img  src="@/assets/img/index/icon (5).png" alt=""/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from '@/api/settings/api.js';
export default {
  data() {
    return {

      imgList: [
        { id: 1, path: "icon (1).png" },
        { id: 2, path: "icon (2).png" },
        { id: 3, path: "icon (3).png" },
        { id: 4, path: "icon (4).png" },
        { id: 5, path: "icon (5).png" },
      ],
      imgList2: [
        { id: 1, path: "icon (1).png" },
        { id: 2, path: "icon (2).png" },
        { id: 3, path: "icon (3).png" },
        { id: 4, path: "icon (4).png" },
        { id: 5, path: "icon (1).png" },
        { id: 6, path: "icon (2).png" },
      ],
    };
  },
  methods:{
    toDemo() {
      window.href = 'http://demo.tradewisesoftware.com'
    },
    videoFullScreen(){
      const video = document.getElementById('video');
      // 设置视频宽度和高度为视窗的宽度和高度
      video.width = window.innerWidth;
      video.height = window.innerHeight;
    },
    setVideoRatio(){
      // const video = this.$refs.video;
      // 假设你想要的宽高比为16:9
      // const ratio = 10 / 1;
      // 设置宽度和高度来保持比例
      // video.style.width = '1080px'; // 或者你想要的任何尺寸
      // video.style.height = '360px'; // 这将自动保持16:9的比例

      // 如果你想要使用aspect-ratio
      // video.style.aspectRatio = ratio + ''; // 将比例转换为字符串

      const video = this.$refs.video;
      const width = video.videoWidth;
      const height = video.videoHeight;
      const aspectRatio = width / height;

      // 设置视频容器的宽度和高度
      this.$refs.videoContainer.style.width = `${width}px`;
      this.$refs.videoContainer.style.height = `${height}px`;

      // 根据视频的宽高比调整样式
      this.$refs.videoElement.style.width = '100%';
      this.$refs.videoElement.style.height = 'auto';
    }
  },
  mounted() {
    // window.addEventListener('resize', this.videoFullScreen);
  },
  destroyed() {
    // window.removeEventListener('resize')
  }
};
</script>

<style lang="scss" scoped>
.home {
  .header {
    height: auto;
    box-sizing: border-box;
    //background: url("~@/assets/Tradewise.gif") no-repeat;
    background-size: 100% 100%;
    object-fit: cover;
    #video {
      object-fit: contain;
    }
  }

}
//@media screen and (min-width: 800px) and (max-width: 1700px) {
//  .home .homeBanner .WebMain .img img {
//    width: 690px;
//  }
//  .home .bottom .partner .center,
//      .bottom {
//        flex-wrap: wrap;
//        justify-content: space-around;
//      }
//  .home .bottom .crm .design .design-box .right-label .title {
//    margin: 12% 12% 6% 12%;
//  }
//}

@media screen and (min-width: 992px) {
  .bottom {
    .crm {
      padding: 80px 0;
      text-align: center;
      .title {
        display: inline-block;
        margin: 0 auto;
        margin-bottom: 60px;
        font-weight: 900;
        p {
          line-height: 26px;
          &:last-child {
            margin-top: 15px;
            width: 100px;
            height: 2px;
            background: var(--theme-warn-color);
          }
        }
      }
      .main {
        display: flex;
        justify-content: space-between;
        .fadeInLeft {
          width: 40%;
          height: 240px;
        }
        .p16 {
          width: 50%;
          display: flex;
          align-items: center;
          .desc {
            width: 86%;
            text-align: left;
            font-size: 18px;
            font-weight: 200;
            line-height: 24px;
          }
          //.desc:nth-child(2){
          //  margin-top: 16px;
          //}
        }
      }
      .radiate{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 50px;
        .flex {
          //height: 5em;
          display: flex;
          justify-content: space-between;
          .func {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .func .icon {
            width: 3em;
            height: 3em;
            margin-right: 1em;
          }
          .func .subtitle {
            width: 9em;
            text-align: left;
            font-size: 18px;
            line-height: 24px;
          }
        }

        .short{
          width: 66%;
          //background-color: pink;
        }
        .long {
          width: 96%;
          margin: 10em 0;
        }
        .center-img {
          width: 36%;
          height: auto;
          position: absolute;
          top: 0;
          left: 32%;
        }
      }
      .design {
        margin-top: 2rem;
        //height: 560px;
        //height: 26em;
        .design-box {
          width: 90%;
          height: 100%;
          background-color: #3e3f41;
          position: relative;
          background-size: 100% 100%;
          border-radius: 0 12px 12px 0;
          .triangle {
            width: 0;
            height: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            border-left: 100px solid transparent;
            border-right: 0px solid transparent;
            //border-bottom: 32em solid #879499;
          }
          .more-phone {
            height: 100%;
            width: auto;
            position: absolute;
            top: 6%;
            left: 10%;
          }
          .right-label {
            width: 40%;
            //height: 40%;
            position: absolute;
            background-color: #fff;
            right: -6%;
            top: 28%;
            border-radius: 8px;
            opacity: 0.9;
            color: #1a1a1a;
            text-align: left;
            .title {
              margin: 6% 12% 4% 12%;
            }
            .subtitles {
              padding:0 4% 0 12%;
              text-align: left;
              font-size: 18px;
              line-height: 18px;
              font-family: Oswald-Light;
            }
          }
          .light-tri{
            border-bottom-color: #565D66;
          }
          .dark-tri{
            border-bottom-color: #2B2D33;
          }

        }

      }
      p {
        line-height: 20px;
        // text-align: center;
      }
      img {
        // width: 738px;
        //height: 615px;
        //margin-top: 17px;
      }
    }


    .about {
      padding: 125px 0;

      background: url("../../assets/img/index/背景-min.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      .left {
        position: relative;
        width: 860px;
        img {
          width: 100%;
          position: absolute;
          top: -32px;
          left: 0;
        }
      }
      .right {
        margin-left: 60px;
        width: 880px;
        height: 350px;
        background: #ffffff;
        border-radius: 12px;
        opacity: 0.95;
        box-shadow: 0px 0px 10px 0px rgba(43,45,51,0.2);

        color: #333333;
        padding: 62px 60px;
        padding-right: 210px;
        box-sizing: border-box;
        p {
          //font-family: ArialHebrew;
          color: #333333;
          line-height: 28px;
        }
        .title {
          margin-bottom: 20px;
          p {
            font-weight: bold;
            color: #1a1a1a;
            line-height: 22px;
            &:last-child {
              width: 60px;
              height: 2px;
              background: #ff8e2f;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .partner {
      padding: 50px 0;
      text-align: center;
      .title {
        display: inline-block;
        margin-bottom: 50px;
        p {
          line-height: 26px;
          font-weight: bold;
          &:last-child {
            width: 60px;
            height: 2px;
            background: var(--theme-warn-color);
            margin-top: 20px;
          }
        }
      }
      .item {
        width: 200px;
        height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        &:hover {
          transition: all 0.5s;
          background: #ffffff;
          box-shadow: 0px 0px 11px 0px rgba(67, 67, 67, 0.48);

        }
        img {
          width: 140px;
        }
      }
      .center,
      .bottom {
        display: flex;
        justify-content: space-between;
      }
      .center {
        padding: 0 40px;
        margin-bottom: 30px;
      }
    }
  }
  .xs {
    display: none;
  }
}
@media screen and (min-width:768px) and (max-width:991.98px){
  .home .bottom .crm .main {
    display: flex;
  }
  .home .bottom .crm .design {
    height: 26em;
  }
  .home .bottom .crm .design .design-box .right-label .subtitle {
    line-height: 24px;
    width: 8em ;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px){
  .home .bottom .crm .main {
    display: flex;
  }
  .home .bottom .crm .design {
    height: 26em;
  }
  .home .bottom .crm .design .design-box .right-label .subtitle {
    line-height: 24px;
    width: 8em ;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px){
  .home .bottom .crm .main {
    display: flex;
  }
  .home .bottom .crm .design {
    height: 26em;
  }
  .home .bottom .crm .design .design-box .right-label {
    width: 40% !important;
    height: 50% !important;
  }
  .home .bottom .crm .design .design-box .right-label .title {
    margin: 6% 12% 4% 12% !important;
  }
  .home .bottom .crm .design .design-box .right-label .subtitle {
    line-height: 24px;
    width: 8em ;
  }
  .home .bottom .crm .design .design-box .triangle {
    border-bottom: 25em solid;
  }

}
@media screen  and (min-width: 1919px){
  .home .bottom .crm .main {
    display: flex;
  }
  .home .bottom .crm .design {
    margin-top: 10em;
    height: 520px;
  }
  .home .bottom .crm .main .fadeInLeft {
    width: 42%;
    height: auto;
  }
  .bottom .crm .radiate .long {
    margin: 14em 0;
  }
  .home .bottom .crm .design .design-box .right-label .title {
    margin: 7% 12% 4% 12% ;
  }
  .home .bottom .crm .design .design-box .right-label{
    width: 50%;
    height: 50%;
  }
  .home .bottom .crm .design .design-box .right-label .more-phone{
    width: 46%;
    height: auto;
  }
  .home .bottom .crm .design .design-box {

  }
  .home .bottom .crm .design .design-box .right-label .subtitle {
    line-height: 24px;
    width: 12em !important;
  }
  .home .bottom .crm .design .design-box .triangle {
    border-bottom: 32em solid;
  }
  .home .bottom .crm .design .design-box .right-label .subtitles {
    line-height: 24px;
  }

}
@media screen and (max-width: 992px) {
  .bottom {
    display: none;
  }
  .xs {
    .crm {
      padding: 32px 0;
      text-align: center;
      .title {
        font-size: 20px;
        margin-bottom: 16px;
        text-align: center;
        display: inline-block;
        font-weight: 900;
        p {
          line-height: 26px;
          &:last-child {
            margin-top: 15px;
            width: 100px;
            height: 2px;
            background: var(--theme-warn-color);
          }
        }
      }
      .main {
        display: block;
        .p16 {
          width: 100%;
          display: flex;
          align-items: center;
          .desc {
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 200;
            line-height: 20px;
          }
          //.desc:nth-child(2){
          //  margin-top: 16px;
          //}
        }
        .fadeInLeft {
          margin-top: 16px;
          width:100%;
          height: auto;
        }
      }
      .radiate{
          .func {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .func .icon {
            width: 2em;
            height: 2em;
            margin-right: 1em;
          }
          .func .subtitle {
           flex: 1;
            text-align: left;
            font-size: 14px;
            line-height: 24px;
          }
        .func:nth-child(2) {
          margin-top: 8px;
        }
        .short{
          width: 100%;
        }
        .long {
          width: 100%;
          margin: 8px 0;
        }
        .center-img {
          width: 100%;
          height: auto;
          height: auto;

        }
      }
      .design {
        //background: url('~@/assets/img/home/more_phone.png') no-repeat;
        background-size: 100% 100%;
        height: auto;
        width: 100%;
        margin-top: 2em;
        .right-label {
          width: 100%;
          height: 22em;
          background-color: #fff;
          border-radius: 8px;
          opacity: 0.9;
          color: #1a1a1a;
          text-align: left;
          .title {
            font-size: 20px;
            margin: 2% 6% 2% 12%;
          }
          .subtitles {
            padding:0 4% 0 12%;
            text-align: left;
            font-size: 14px;
            line-height: 18px;
            font-family: Oswald-Light;
          }
          .more-phone {
            margin-top: 1em;
            height: auto;
            width: 80%;
            margin-left: 10%;
          }
        }

      }
      p {
        line-height: 20px;
        // text-align: center;
      }
      img {
        // width: 738px;
        //height: 615px;
        //margin-top: 17px;
      }
    }
    .partner {
      padding: 16px 0;
      text-align: center;
      .title {
        font-size: 20px;
        display: inline-block;
        margin-bottom: 16px;
        p {
          line-height: 26px;
          font-weight: bold;
          &:last-child {
            width: 60px;
            height: 2px;
            background: var(--theme-warn-color);
            margin-top: 20px;
          }
        }
      }
      .center {
        margin-bottom: 30px;
        .item {
          width: 100%;
          height: auto;
          display: flex;

          justify-content: space-around;
          align-items: center;
          border-radius: 16px;
          img {
            width: 80px;
            height: auto;
            &:hover {
              transition: all 0.5s;
              background: #ffffff;
              box-shadow: 0px 0px 11px 0px rgba(67, 67, 67, 0.48);
              border-radius: 8px;
            }
          }
        }
        .item:nth-child(2){
          margin-top: 24px;
        }
      }
    }
  }

}
</style>